import ButtonScrollTop from "../components/ButtonScrollTop";

export const install = () => {

  const menuToggle = document.getElementById('button-toggle-menu');

  if(menuToggle) {
    //mobile menu toggle button
    menuToggle.addEventListener('click',() => {

      let isOpen = document.body.classList.contains('menu-open');

      if(isOpen) {
        //hide after fadeout
        document.body.classList.add('menu-closing');
        setTimeout(() => {
          document.body.classList.remove(...['menu-open', 'menu-closing']);
        }, 250);

      } else {
        //show
        document.body.classList.add('menu-open');
      }
    });
  }

  new ButtonScrollTop('button-scroll-top');
};
